var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden",
    },
    [
      !_vm.allInfoLoaded || _vm.fetchingTroubleshoot
        ? _c(
            "v-card",
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type: "card, list-item-two-line, divider, list-item-avatar-three-line, divider, list-item-avatar-three-line, divider, actions",
                  width: "350",
                },
              }),
            ],
            1
          )
        : _vm.troubleshootList
        ? _c(
            "v-card",
            { attrs: { "max-width": "600" } },
            [
              _c(
                "v-card-title",
                { staticClass: "justify-center align-center nuvolos-header" },
                [
                  _c("v-img", {
                    staticClass: "py-5",
                    attrs: {
                      "max-width": "200",
                      "max-height": "22",
                      src: require("@/assets/Nuvolos-transparent.svg"),
                    },
                  }),
                ],
                1
              ),
              [
                _c(
                  "v-card-text",
                  _vm._l(_vm.troubleshootList, function (help) {
                    return _c(
                      "v-alert",
                      {
                        key: help.reqid,
                        staticClass: "mb-0 mt-4",
                        attrs: { type: "warning", text: "" },
                      },
                      [_c("div", { domProps: { innerHTML: _vm._s(help.msg) } })]
                    )
                  }),
                  1
                ),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.$auth.logout()
                          },
                        },
                      },
                      [_vm._v("Log out")]
                    ),
                  ],
                  1
                ),
              ],
            ],
            2
          )
        : _vm.userInfo
        ? _c(
            "v-card",
            { attrs: { width: "350" } },
            [
              _c(
                "v-card-title",
                { staticClass: "justify-center align-center nuvolos-header" },
                [
                  _c("v-img", {
                    staticClass: "py-5",
                    attrs: {
                      "max-width": "200",
                      "max-height": "22",
                      src: require("@/assets/Nuvolos-transparent.svg"),
                    },
                  }),
                ],
                1
              ),
              _vm.validInvitation
                ? [
                    _c(
                      "h2",
                      {
                        staticClass: "secondary--text text-center text-h6 mt-5",
                      },
                      [_vm._v("Join Nuvolos")]
                    ),
                    _c("v-card-text", [
                      _vm._v(
                        "You have received invitation(s) to Nuvolos. Please review the grants and continue if you wish to accept."
                      ),
                    ]),
                    _c("v-subheader", [_vm._v("REVIEW GRANTS")]),
                    _c("v-divider"),
                    _c("v-subheader", [_vm._v("Organization")]),
                    _c(
                      "v-card-text",
                      { staticClass: "pt-0" },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "grey lighten-3",
                            attrs: { flat: "" },
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.roles[0].Organization)),
                                ]),
                                _c("v-spacer"),
                                _c(
                                  "v-chip",
                                  {
                                    attrs: {
                                      small: "",
                                      outlined: "",
                                      color: "primary",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.orgRole) + " ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _vm.spaceRole
                      ? [
                          _c("v-subheader", [_vm._v("Space")]),
                          _c(
                            "v-card-text",
                            { staticClass: "pt-0" },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-3",
                                  attrs: { flat: "" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("b", [
                                        _vm._v(_vm._s(_vm.spaceRole.Space)),
                                      ]),
                                      _c("v-spacer"),
                                      _vm.spaceRole
                                        ? _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                outlined: "",
                                                color: "primary",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.spaceRole.Name) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                        ]
                      : _vm._e(),
                    _vm.spaceInvites.length > 0
                      ? [
                          _c("v-subheader", [_vm._v("Space")]),
                          _c(
                            "v-card-text",
                            { staticClass: "pt-0" },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-3",
                                  attrs: { flat: "" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.spaceInvites[0].Space)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("v-divider"),
                                  _vm.spaceInvites.length > 0
                                    ? [
                                        _vm._l(
                                          _vm.spaceInvites,
                                          function (r, index) {
                                            return [
                                              _c(
                                                "v-list-item",
                                                { key: `grants-${index}` },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    _vm._l(
                                                      _vm.user_pretty(r),
                                                      function (value, name) {
                                                        return _c(
                                                          "v-list-item-subtitle",
                                                          {
                                                            key: name,
                                                            staticClass:
                                                              "text-truncate",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(name) +
                                                                ": "
                                                            ),
                                                            _c(
                                                              "b",
                                                              {
                                                                staticClass:
                                                                  "secondary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(value)
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              index <
                                              _vm.spaceInvites.length - 1
                                                ? _c("v-divider", {
                                                    key: `divider-${index}`,
                                                  })
                                                : _vm._e(),
                                            ]
                                          }
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                        ]
                      : _vm._e(),
                    _c("v-card-text", [_c("router-view")], 1),
                  ]
                : _c(
                    "v-card-text",
                    { staticClass: "pt-5" },
                    [
                      _c(
                        "v-alert",
                        { attrs: { dense: "", text: "", type: "error" } },
                        [
                          _vm._v(
                            "We are sorry but this invitation is no longer valid."
                          ),
                        ]
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                elevation: "0",
                                block: "",
                                color: "primary",
                                to: { name: "login" },
                              },
                            },
                            [_vm._v("Sign in to Nuvolos")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }